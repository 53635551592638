
import {defineComponent, ref, watch} from "vue";
import {GetSpaceId} from "@/core/composite/composite";
import Avatar from "@/components/base/common/Avatar.vue";
import DateTimeFormat from "@/components/base/datetime/DateTimeFormat.vue";
import EntityLoading from "@/components/base/loading/EntityLoading.vue";
import DocUserService from "@/core/services/DocUserService";
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";

export default defineComponent({
  name: "TrainingDocs",
  components: {TrainingUserStatus, DateTimeFormat, Avatar, EntityLoading},
  props: {
    trainId: {}
  },
  setup(props) {

    const loading = ref(true);
    const docs = ref<any>([]);
    const loadDocs = (id) => {
      loading.value = true;
      DocUserService.getDocs(id).then(res=>{
        docs.value = res;
      }).finally(()=>{
        loading.value = false;
      })
    }
    loadDocs(props.trainId);
    watch(()=>props.trainId, cb=>{
      loadDocs(cb)
    })
    return {
      ...GetSpaceId(),
      docs,
      loading,
    }
  },
  watch: {}
})
