
import {computed, defineComponent, watch} from "vue";
import {useDocUserStore} from "@/store/doc.user.store";
import {GetSpaceId, LoadPanel, PinaLoadEntity} from "@/core/composite/composite";
import {setCurrentPageBreadcrumbsWithParams} from "@/core/helpers/breadcrumb";
import TrainingDocs from "@/views/training/TrainingDocs.vue";
import TrainingProgress from "@/views/training/TrainingProgress.vue";
import QuickAction from "@/components/base/action/QuickAction.vue";

export default defineComponent({
  name: "Training",
  components: {QuickAction, TrainingProgress, TrainingDocs},
  props: {
    trainingId: {type: String},
  },
  setup() {
    const docUserStore = useDocUserStore();
    const spaceId = GetSpaceId().spaceId;
    const entity = computed<any>(() => docUserStore.entity);
    watch(entity, (cb: any) => {
      const pageTitle = 'Training'

      const data: any = []

      data.push({
        link: true,
        router: `/` + spaceId.value + '/training/progress',
        text: 'Training'
      })

      data.push({link: false, router: '', text: cb.doc.name})
      setCurrentPageBreadcrumbsWithParams(pageTitle, data)
    })


    return {
      entity,
      ...LoadPanel(),
      ...PinaLoadEntity(docUserStore)
    }
  },
  methods: {}
})
