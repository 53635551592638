
import {defineComponent, ref, watch} from "vue";
import DocUserService from "@/core/services/DocUserService.ts"
import TrainingUserStatus from "@/views/training/TrainingUserStatus.vue";


export default defineComponent({
  name: "TrainingProgress",
  components: {TrainingUserStatus},
  props: {
    docId: {type: String},
    status: {},
  },
  setup(props) {
    const percent = ref(0)
    const loadProgress = (id) => {
      DocUserService.countProgress(id).then(res => {
        percent.value = res.percent;
      })
    }
    loadProgress(props.docId);
    watch(() => props.docId, cb => {
      loadProgress(cb);
    })

    return {
      percent
    }
  }
})
