import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "d-flex flex-row-fluid"
}
const _hoisted_2 = { class: "flex-column flex-row-fluid" }
const _hoisted_3 = { class: "card shadow-sm card-docs flex-row-fluid" }
const _hoisted_4 = { class: "card-header" }
const _hoisted_5 = { class: "card-title" }
const _hoisted_6 = {
  key: 0,
  class: "text-muted fs-5 ps-1"
}
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  key: 0,
  class: "me-9 my-1 float-end"
}
const _hoisted_9 = { class: "menu-item px-3" }
const _hoisted_10 = { class: "card-body" }
const _hoisted_11 = { class: "flex-column flex-row-auto w-300px ms-lg-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_QuickAction = _resolveComponent("QuickAction")!
  const _component_TrainingDocs = _resolveComponent("TrainingDocs")!
  const _component_training_progress = _resolveComponent("training-progress")!

  return (_ctx.entity.id)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("h3", _hoisted_5, [
                _createTextVNode(_toDisplayString(_ctx.entity.doc.name) + " ", 1),
                (_ctx.entity.doc.description)
                  ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.entity.doc.description), 1))
                  : _createCommentVNode("", true)
              ]),
              _createElementVNode("div", _hoisted_7, [
                (_ctx.entity?.id)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                      _createVNode(_component_QuickAction, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_9, [
                            _createElementVNode("a", {
                              href: "javascript:void(0);",
                              class: "menu-link px-3",
                              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadPanel({objectId : _ctx.entity.id , objectType: 'DOC_USER'}, 'PROPERTY')))
                            }, "Properties")
                          ])
                        ]),
                        _: 1
                      })
                    ]))
                  : _createCommentVNode("", true)
              ])
            ]),
            _createElementVNode("div", _hoisted_10, [
              _createVNode(_component_TrainingDocs, {
                "train-id": _ctx.entity.id,
                ref: "trainingDocRef"
              }, null, 8, ["train-id"])
            ])
          ]),
          (_ctx.entity.doc.faq)
            ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [], 64))
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_11, [
          _createVNode(_component_training_progress, {
            "doc-id": _ctx.entity.id,
            status: _ctx.entity.status
          }, null, 8, ["doc-id", "status"])
        ])
      ]))
    : _createCommentVNode("", true)
}